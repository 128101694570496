.container {
  background-color: var(--blue6);
  display: flex;
  justify-content: center;
}

.grid-wrapper {
  max-width: 872px;
  padding-top: 80px;
}

.masonry {
  column-count: 2;
  column-gap: 30px;
}

.image-wrapper {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 55%;
}

.article-card {
  background-color: var(--white);
  box-shadow: 0px 1px 12px rgba(89, 87, 92, 0.08);
  display: inline-block;
  margin: 0 0 30px 0;
  width: 100%;
  .image-wrapper {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 55%;
  }
  .text-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 33px 33px 44px 33px;
    h3, small {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & h3 {
      text-transform: uppercase;
    }
    & h4 {
      color: var(--blue2);
      font-weight: bold;
      padding-top: 10px;
      text-decoration: underline;
    }
    & small {
      padding: 15px 0 0;
    }
    .title-wrapper {
      min-height: 150px;
    }
  }
}

.featured-card {
  display: inline-block;
  width: 100%;
  background-color: var(--blue6);
  .wrapper {
    padding: 44px 22px;
  }
  & h2 {
    text-transform: uppercase;
    padding-bottom: 44px;
    color: var(--blue1);
  }
  a,
  a h4 {
    color: var(--blue1);
    font-weight: bold;
  }
}

.featured-card-image {
  background-color: var(--blue6);
  .wrapper {
    padding: 44px 22px 44px 22px;
  }
  & h2 {
    text-transform: uppercase;
    padding-bottom: 44px;
    color: var(--blue1);
  }
  a,
  a h4 {
    color: var(--blue1);
    font-weight: bold;
  }
  & img {
    width: 100%;
    height: auto;
    padding-bottom: 44px;
  }
}

.external-card {
  display: inline-block;
  width: 100%;
  background-color: var(--white);
  margin: 0 0 30px;
  box-shadow: 0 1px 12px rgba(89, 87, 92, 0.08);
  .text-wrapper {
    padding: 33px 33px 44px 33px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & h3 {
      color: var(--salesforceNeutral);
      text-transform: uppercase;
      padding-bottom: 44px;
    }
    a,
    a h4 {
      color: var(--blue2);
      font-weight: bold;
    }
  }
}
@media only screen and (max-width: 767px) {
  .grid-wrapper {
    width: 100%;
  }
  .masonry {
    column-count: 1;
    padding: 10px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .grid-wrapper {
    width: 100%;
  }
  .masonry {
    column-count: 2;
    padding: 10px;
  }
}
